import React, { Component } from 'react';

class ImagePopUps extends Component {
	constructor(props){
		super(props);

		this.state = {
			info: props.data,
			study: {},
			showComponent: false
		}

		this.handleClick = this.handleClick.bind(this);
		this.handleHoverIn = this.handleHoverIn.bind(this);
		this.handleHoverOut = this.handleHoverOut.bind(this);
	}

	componentDidMount(){
		this.state.info.data.case_study.get().then(res =>{
			const prevState = this.state.info;
			this.setState({
				info: prevState,
				study: res.data(),
				showComponent: false
			})
		})
	}

	handleClick(e){
		var toggleShow = !this.state.showComponent;

		this.setState({
			info: this.state.info,
			study: this.state.study,
			showComponent: toggleShow
		})

		if(toggleShow === true){
			document.body.classList.add('no-scroll');
		}

		else{
			document.body.className = document.body.className.replace("no-scroll","");
		}
	}

	handleHoverIn(e){
		e.currentTarget.querySelector('img').src = this.state.info.data.hover_url;
	}

	handleHoverOut(e){
		e.currentTarget.querySelector('img').src = this.state.info.data.url;
	}

	

	render(){

		const info = this.state.info;
		let showComponent = this.state.showComponent;
		var caseStudyInfo = this.state.study;

		var aboutStyling;

		if(caseStudyInfo.background_1 != null) {
			aboutStyling = {backgroundImage:`url(${caseStudyInfo.background_1})`, backgroundPosition:'center'}
		}

		else {
			aboutStyling = {backgroundColor: 'black'}
		}
		
		var resultStyling;
		var resultBackgroundColor;

		if(caseStudyInfo.background_2 != null) {
			resultStyling = {backgroundColor: 'black',  backgroundImage:`url(${caseStudyInfo.background_2})`, backgroundPosition:'center'}
			resultBackgroundColor = {backgroundColor: 'black', color: 'white'}
		}

		else {
			resultStyling = {backgroundColor: '#FEEC35'}
			resultBackgroundColor = {backgroundColor: '#FEEC35', color: 'black'}
		}

		var challengesHandler = [];

		try{
			for(let i = 0; i < caseStudyInfo.challenges.length; i++){
				challengesHandler.push(<p>{caseStudyInfo.challenges[i]}</p>)
			}
		}
		catch(error){};

		var objectivesHandler = [];

		try{
			for(let i = 0; i < caseStudyInfo.objectives.length; i++){
				objectivesHandler.push(<p>{caseStudyInfo.objectives[i]}</p>)
			}
		}
		catch(error){};

		var resultsHandler = [];

		try{
			for(let i = 0; i < caseStudyInfo.results.length; i++){
				resultsHandler.push(<p>{caseStudyInfo.results[i]}</p>)
			}
		}
		catch(error){};

		var tableHandler = [];
		tableHandler.push(<tr><th colSpan='2'>HIGHLIGHTS</th></tr>)
		try{
			for(var statistic in caseStudyInfo.statsTable){
				tableHandler.push(<tr><td>{statistic}</td><td>{caseStudyInfo.statsTable[statistic]}</td></tr>);
			}
		}
		catch(error){};

		return(
			<div className="popUpHolder" >

			<div className="image-icon-div" onClick={this.handleClick} onMouseOver={this.handleHoverIn} onMouseOut={this.handleHoverOut} >
			<img className="image-button"  src={info.data.url} alt={info.data.alt} />
			<div className="corner-icon"><img src="./../../assets/imageAccessible.png" alt="" /></div>
			</div>
			{showComponent && 
			<div>
				<div className="popUp-overlay"></div>
				<div className="popUp">
					<div className="popUp-section popUp-header">
						<div className="popUp-image-background" style={aboutStyling}></div>
						<img src={info.data.url} alt={info.data.alt} />
						<p>{caseStudyInfo.about}</p>
					</div>
					<div className="popUp-section">
						<h2>CHALLENGE</h2>
						{challengesHandler}
					</div>
					<div className="popUp-section">
						<h2>OBJECTIVE</h2>
						{objectivesHandler}
					</div>
					<div className="popUp-section results" style={resultBackgroundColor}>
						<div className="popUp-image-background" style={resultStyling}></div>
						<h2>RESULT</h2>
						{resultsHandler}
					</div>

					<div className="popUp-section">
						<table className="statsTable" border='1'>{tableHandler}</table>
						<div className="popUp-back" onClick={this.handleClick}> &lt;- Go Back </div>
					</div>
				</div>

				<div className="popUp-close" onClick={this.handleClick}> x </div>
			</div>
			}
			</div>
			)
	}

}

export default ImagePopUps;
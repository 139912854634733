import React from 'react';
import Homepage from './components/homepage.js';
import Contact from './components/contact.js';
import About from './components/about.js';
import NotFound from './components/notFound.js';
import './css/fonts.css';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
} from 'react-router-dom';

function App() {

  return (
    <Router>
      <div>
      <Switch>

        <Route exact path="/" component={Homepage} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/about" component={About} />

        <Route component={NotFound} />
        </Switch>
      </div>

    </Router>
    );


}

export default App;

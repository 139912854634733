import React, { Component } from "react";
import './../css/templateHnF.css';
import { HashLink as Link } from 'react-router-hash-link';

class Header extends Component{
	render(){
		return(
			<nav scroll='no' id="myNav" className="blackColor">
        <div className="nav_container">
          <Link to={{pathname:'/', hash:'#logo'}}>
            Zena Digital Group
          </Link>
        </div>
        <div className="nav_container" id="right">
          <ul>
            <li><Link to="/about">about</Link></li>
            <li><Link to={{pathname:'/', hash:'#past_work'}}>work</Link></li>
            <li><Link to="/contact">contact</Link></li>
          </ul>
        </div>
      </nav>
			)
	}
}

export default Header;
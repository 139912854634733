import React, { Component } from 'react';
import db from './firebase';
import ImagePopUps from './imagepopups'

class GetImages extends Component {
	constructor(){
		super();
		this.state = {
			listOfClients: []
		};
	}

	componentDidMount(){
		db.collection("clients").get().then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				var prevList = this.state.listOfClients;
				prevList.push({
					id: doc.id,
					data: doc.data()
				});
				this.setState({
					listOfClients: prevList
				});
			});
		});
	}

	render() {
		let columnSize = Math.ceil(this.state.listOfClients.length / 5);
		const columnA = this.state.listOfClients.map((client,index) =>

			<div key={client.id}>
			{index < columnSize && client.data.case_study == null && <img className="clientImage" src={client.data.url} alt={client.data.alt} />}
			{index < columnSize && client.data.case_study != null && <ImagePopUps data={client} />}
			</div>	
		)

		const columnB = this.state.listOfClients.map((client,index) =>

			<div key={client.id}>
			{index >= columnSize && index < columnSize*2 && client.data.case_study == null && <img className="clientImage" src={client.data.url} alt={client.data.alt} />}
			{index >= columnSize && index < columnSize*2 && client.data.case_study != null && <ImagePopUps data={client} />}
			</div>	
		)

		const columnC = this.state.listOfClients.map((client,index) =>

			<div key={client.id}>
			{index >= columnSize*2 && index < columnSize*3 && client.data.case_study == null && <img className="clientImage" src={client.data.url} alt={client.data.alt} />}
			{index >= columnSize*2 && index < columnSize*3 && client.data.case_study != null && <ImagePopUps data={client} />}
			</div>	
		)

		const columnD = this.state.listOfClients.map((client,index) =>

			<div key={client.id}>
			{index >= columnSize*3 && index < columnSize*4 && client.data.case_study == null && <img className="clientImage" src={client.data.url} alt={client.data.alt} />}
			{index >= columnSize*3 && index < columnSize*4 && client.data.case_study != null && <ImagePopUps data={client} />}
			</div>	
		)

		const columnE = this.state.listOfClients.map((client,index) =>

			<div key={client.id}>
			{index >= columnSize*4 && index < columnSize*5 && client.data.case_study == null && <img className="clientImage" src={client.data.url} alt={client.data.alt} />}
			{index >= columnSize*4 && index < columnSize*5 && client.data.case_study != null && <ImagePopUps data={client} />}
			</div>	
		)
			
		return (
			<div className="image-row">
				<div className="image-column">{columnA}</div>
				<div className="image-column">{columnB}</div>
				<div className="image-column">{columnC}</div>
				<div className="image-column">{columnD}</div>
				<div className="image-column">{columnE}</div>
			</div>
			)
	}
}



export default GetImages;
import firebase from 'firebase/app';
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyDRhWwH7yaamEZSW5PpCv6sOUYFRemfyUQ",
  authDomain: "zdg-site.firebaseapp.com",
  databaseURL: "https://zdg-site.firebaseio.com",
  projectId: "zdg-site",
  storageBucket: "zdg-site.appspot.com",
  messagingSenderId: "783405785452",
  appId: "1:783405785452:web:271acc63bb20b60c"
};

firebase.initializeApp(config);

const db = firebase.firestore();

export default db;
import React, { Component } from 'react';
import Image from './../assets/zena-icons-animation-copywriting.gif';
import './../css/about.css';
import Header from './header.js';
import Footer from './footer.js';
import MetaTags from 'react-meta-tags';


class About extends Component {
	render(){
		return(
			<div className="fakeBody">
			<MetaTags>
				<title>About | Zena Digital Group</title>
				<meta name="description" content="Meet the team: Zena H. (Founder and CEO), Gisell F. (Digital Media Manager), Kelvin K. (Art Director), Tom H. (Analytics), and Bash B. (Director and Media)" />
				<meta property="og:title" content="About" />
			</MetaTags>
			<Header />
				<div className="nav-blank-adjust" />
				<div className="section-about">
					<div className="section-column">
						<img src={Image} alt=""/>
					</div>

					<div className="section-column about-section-right">
						<div className="section-row-R">
						<p>Zena Digital Group is a boutique agency driven by diversity, servicing 
						a unique blend of niche clients through strategy, content, media planning, 
						buying reporting and analytics. </p>
						</div>

						<div className="section-row-R">
						<h2>STAFF</h2>
						<ul id="staff-list">
							<li className="staff"><b>Zena H.,</b> Founder, CEO</li>
							<li className="staff"><b>Gisell F.,</b> Digital Media Manager</li>
							<li className="staff"><b>Kelvin K.,</b> Art Director</li>
							<li className="staff"><b>Tom H.,</b> Analytics</li>
							<li className="staff"><b>Bash B.,</b> Director, Media</li>
						</ul>
						</div>
					</div>
				</div>
				<Footer />
			</div>
			)
	}
}

export default About;
import React, { Component } from 'react';
import './../css/contact.css';
import Header from './header.js';
import Footer from './footer.js';
import MetaTags from 'react-meta-tags';

class Contact extends Component{


	render(){
		const key = 'AIzaSyBPS3AhcMjPs5bTnN-4AAeQ6G4r7xvQY4U';
		const map_url = "https://www.google.com/maps/embed/v1/place?key=" + key + "&q=26+BROADWAY+NEW+YORK,+NY+10004"
		return(
			<div className="fakeBody">
			<MetaTags>
				<title>Contact | Zena Digital Group</title>
				<meta name="description" content="Contact Us: 26 Broadway New York, NY 10004. United States. T: +1 201 887 3156. E: zena@zenadigitalgroup.com." />
				<meta property="og:title" content="Contact" />
			</MetaTags>
			<Header />
				<div className="nav-blank-adjust" />
				<div className="section-contact">
					<div className="section-column iframe-handle">
						<iframe title="google-maps" frameBorder="0" src={map_url}></iframe>
					</div>

					<div className="section-column contact-section-right">
					<div className="section-row-R">
						<h2>DON'T BE SHY</h2>
						<ul>
							<li>201-887-3156</li>
							<li>zena@zenadigitalgroup.com</li>
						</ul>
					</div>

					<div className="section-row-R">
						<h2>KEEP IN TOUCH</h2>
						<ul>
							<li><a href="https://www.facebook.com/thezigital">facebook</a></li>
							<li><a href="https://twitter.com/thezigital">twitter</a></li>
							<li><a href="https://www.instagram.com/thezigital/">instagram</a></li>
							<li><a href="https://www.linkedin.com/company/zena-digtial-group/">linkedin</a></li>
						</ul>
					</div>
					</div>
				</div>
				<Footer />
			</div>
			)
	}
}

export default Contact;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './../css/notFound.css';


class notFound extends Component{


	render(){
		return(
		<div>
		<center><h2 id="not-found-header">404 PAGE NOT FOUND</h2></center>
		<center><Link to="/">Return to homepage</Link></center>
		</div>
			)
	}
}

export default notFound;
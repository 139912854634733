import React, { Component } from 'react';
import GetImages from './Firebase/getImages';
import logo from './../logo.gif';
import twoyear from './../zena-anniversary.gif';
import checkmark from './../checkmark.png'
import './../css/index.css';
import Header from './header.js';
import Footer from './footer.js';
import MetaTags from 'react-meta-tags';

class Homepage extends Component {

	render() {
		const whiteSpace = {'textDecoration': 'underline', 'whiteSpace': 'pre'};
            const backgroundLogo = {'backgroundImage': `url(${twoyear}`, 'backgroundPosition': 'center center', 'backgroundRepeat': 'no-repeat no-repeat', 'backgroundSize': 'cover'};

		return (
			<div>
                  <MetaTags>
                        <title>Zena Digital Group</title>
                        <meta name="description" content="Zena Digital Group is a boutique media agency with a unique approach to finding the right audiences, creating unique content, defining brand voice and tone, identifying appropriate marketing channels, efficiently allocating budgets, and optimizing accordingly for niche brands." />
                        <meta property="og:title" content="Home" />
                  </MetaTags>
                  <Header />
			<div id="logo" style={backgroundLogo}>
      </div>
      <div className="section" id="about">
      <h2>ABOUT</h2>
      <p>Zena Digital Group is a boutique media agency with a unique approach to finding the right audiences, creating unique content, defining brand voice and tone, identifying appropriate marketing channels, efficiently allocating budgets, and optimizing accordingly for niche brands.</p>
      <p>Most imporantly, we like to work with full transparency.</p>
      </div>
      <div className="section" id="media_planning">
      <h2>SERVICES</h2>
      <h3>Hover Over</h3>
      <h4>MEDIA PLANNING AND STRATEGY</h4>
      <ul>
      <li id="paid_social"><img src={checkmark} alt="" className="checkmark" /><span style={whiteSpace}>   </span> Paid Media &amp; Social</li>
      <li id="marketing"><img src={checkmark} alt="" className="checkmark" /><span style={whiteSpace}>   </span> Marketing &amp; Consumer Ecosystem</li>
      <li id="programmatic_display"><img src={checkmark} alt="" className="checkmark" /><span style={whiteSpace}>   </span> Programmatic Display and Video</li>
      <li id="ppc"><img src={checkmark} alt="" className="checkmark" /><span style={whiteSpace}>   </span> AdWords / SEM / PPC</li>
      </ul>
      <h4>CONTENT CREATION</h4>
      <ul>
      <li id="social_media"><img src={checkmark} alt="" className="checkmark" /><span style={whiteSpace}>   </span> Social Media</li>
      <li id="ad_formatting"><img src={checkmark} alt="" className="checkmark" /><span style={whiteSpace}>   </span> Ad Formatting</li>
      <li id="copywriting"><img src={checkmark} alt="" className="checkmark" /><span style={whiteSpace}>   </span> Copywriting</li>
      <li id="animations"><img src={checkmark} alt="" className="checkmark" /><span style={whiteSpace}>   </span> Animations</li>
      </ul>

      <h4>ANALYTICS</h4>
      <ul>
      <li id="data_sci"><img src={checkmark} alt="" className="checkmark" /><span style={whiteSpace}>   </span> Data Science and Technology Management</li>
      <li id="reporting"><img src={checkmark} alt="" className="checkmark" /><span style={whiteSpace}>   </span> Automated Reporting</li>
      <li id="monitoring"><img src={checkmark} alt="" className="checkmark" /><span style={whiteSpace}>   </span> Monitoring and Optimization</li>
      </ul>
      </div>
      <div className="section" id="past_work">
      <div id="past_work_header">
            <h2>CLIENTS</h2>
            <h3>Hover Over</h3>
      </div>
      <GetImages />
      </div>


      <div className="section" id="contact">
      <div className="contactSection" id="leftContact">
      <h2> DON'T BE SHY </h2>
      <ul><li>201-887-3156</li><li>zena@zenadigitalgroup.com</li></ul>
      </div>
      <div className="contactSection" id="rightContact">
      <h2> KEEP IN TOUCH </h2>
      <ul>
      <li><a href="https://www.facebook.com/thezigital">facebook</a></li>
      <li><a href="https://twitter.com/thezigital">twitter</a></li>
      <li><a href="https://www.instagram.com/thezigital/">instagram</a></li>
      <li><a href="https://www.linkedin.com/company/zena-digtial-group/">linkedin</a></li>
      </ul>
      </div>
      </div>
      <Footer />
      </div>
			)
	}
}



export default Homepage;
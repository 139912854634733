import React, {Component} from "react";
import './../css/templateHnF.css';

class Footer extends Component {
	render(){
		return(
			<div>
			<footer>copyright 2019 all rights reserved</footer>
			</div>

			)

	}
}

export default Footer;